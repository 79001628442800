import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {escapeRegExp} from 'lodash';

import {
  CustomTable,
  FormikSearchInput,
  ProgressIndicator,
  SelectTableCell,
  SelectTableCellData,
  useAlerts,
  User
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {certsUserApi, loggedCourseApi} from '../../api';
import {Course, LogOnBehalfOfFormFields} from '../../types';
import {logOnBehalfOfFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  course: Course
  onToggle: (course?: Course) => void
}
const LogOnBehalfOfModal = ({
                              isOpen,
                              course,
                              onToggle
                            }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [loggableUsers, setLoggableUsers] = useState<User[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const filteredUsers = useMemo(() => {
    return loggableUsers.filter(user => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');

        return selectedUserIds.includes(user.id) ||
          searchTermRegexp.test(user.firstName) ||
          searchTermRegexp.test(user.lastName) ||
          searchTermRegexp.test(user?.certificationNumber ?? '');
      }
    });
  }, [
    selectedUserIds,
    loggableUsers,
    searchText
  ]);

  const initialValues = useMemo(() => ({
    courseId: course.id,
    certsUserIds: selectedUserIds
  }), [
    course,
    selectedUserIds
  ]);

  const handleSubmit = useCallback(async (_: LogOnBehalfOfFormFields,
                                          formikHelpers: FormikHelpers<LogOnBehalfOfFormFields>) => {
    try {
      await loggedCourseApi.logOnBehalfOf({
        courseId: course.id,
        certsUserIds: selectedUserIds
      });
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.LOGGED_COURSE_SUCCESSFUL);
      onToggle(course);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.LOGGED_COURSE_FAILED);
      onToggle();
    }

    setSearchText('');
    setSelectedUserIds([]);
  }, [
    course,
    selectedUserIds,
    onToggle,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleClose = useCallback((formikProps: FormikProps<LogOnBehalfOfFormFields>) => {
    formikProps.resetForm();
    setSelectedUserIds([]);
    setSearchText('');
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    const loadLoggableUsers = async () => {
      try {
        if (isOpen) {
          const loggableUsers = await certsUserApi.findLoggableUsers(course.id);
          setLoggableUsers(loggableUsers);
          setLoadingState(loadingState => ({...loadingState, loading: false}));
        }
      } catch (error) {
        showErrorAlert(messages.USER_LOAD_FAILED);
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      }
    };
    void loadLoggableUsers();
  }, [isOpen, course.id, showErrorAlert]);

  const handleItemSelect = useCallback((selectTableCellData: SelectTableCellData) => {
    const updatedUserIds = selectedUserIds.includes(selectTableCellData.itemId as number) ?
      selectedUserIds.filter(id => id !== selectTableCellData.itemId) :
      [...selectedUserIds, selectTableCellData.itemId as number];
    setSelectedUserIds(updatedUserIds);
  }, [
    selectedUserIds
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {
        title: '',
        className: 'text-nowrap text-center w-10'
      },
      {
        title: 'Last Name',
        className: 'text-nowrap w-30',
        sortKey: 'lastName'
      },
      {
        title: 'First Name',
        className: 'text-nowrap w-30',
        sortKey: 'firstName'
      },
      {
        title: 'Cert #',
        className: 'w-30 text-center'
      }
    ],
    items: filteredUsers,
    noResultsMessage: messages.USERS_NOT_FOUND,
    initialSort: {
      sortKey: 'lastName',
      direction: 'asc' as const
    },
    chainSort: true,
    renderRow: (user: User) => {
      return <tr key={user.id} className={selectedUserIds.includes(user.id) ? 'selected' : ''}>
        <SelectTableCell itemId={user.id}
                         selected={selectedUserIds.includes(user.id)}
                         onChange={handleItemSelect}/>
        <td>{user.lastName}</td>
        <td>{user.firstName}</td>
        <td className="text-center">{user.certificationNumber}</td>
      </tr>;
    }
  }), [
    handleItemSelect,
    selectedUserIds,
    filteredUsers
  ]);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            validationSchema={logOnBehalfOfFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal isOpen={isOpen}
                 autoFocus={false}
                 backdrop="static"
                 size="lg"
                 className="LogOnBehalfOfModal"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>Log Users</ModalHeader>
            <ModalBody>
              {loadingState.loading && <ProgressIndicator/>}
              {!loadingState.loading && <Row>
                <Col>
                  <FormikSearchInput disabled={false}
                                     onSubmit={setSearchText}/>
                  <Form onSubmit={formikProps.handleSubmit}
                        autoComplete="off">
                    <CustomTable {...tableProps}/>
                  </Form>
                </Col>
              </Row>}
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      onClick={formikProps.submitForm}
                      disabled={selectedUserIds.length === 0 || !formikProps.isValid || formikProps.isSubmitting}>
                Save
              </Button>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(LogOnBehalfOfModal);